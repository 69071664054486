<template>
  <PopupLayout>
    <template v-slot:content>
          <slot></slot>
    </template>
  </PopupLayout>
</template>

<script>
import { PopupLayout } from '@appbase/templates'

export default {
  components: { PopupLayout},
 
}
</script>

<style></style>
