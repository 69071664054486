import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import PopupTemplate  from '@survey/views/PopupViews/SurveyPopupTemplate.vue'

 

@Component({
    components: { PopupTemplate },

})
export default class SurveyPopupMaster extends Vue {
    
}
